//
//
//
//
//
//

export default {
  props: {
    blok: {
      type: Object
    },
    columns: {
      type: Number,
      default: 1
    }
  },
  computed: {
    columnCSS() {
      let css = 'w-full '
      switch(this.blok.width) {
        case 'Full':
          css += ''
          break
        case '1/4':
          css += 'lg:w-1/4'
          break
        case '1/3':
          css += 'lg:w-1/3'
          break
        case '1/2':
          css += 'lg:w-1/2'
          break
        case '2/3':
          css += 'lg:w-2/3'
          break
        case '3/4':
          css += 'lg:w-3/4'
          break
        default:
          switch(this.columns) {
            case 1:
              css += ''
              break
            default:
              css += `lg:w-1/${this.columns}`
          }
      }
      return css
    }
  }
}
